<template>
  <div class="inner">
    <div class="card">
      <van-form validate-first @failed="onFailed" ref="pageForm">
        <!-- 名字 -->
        <div class="name">
          <!-- <div class="line1"><span>*</span>提案名称</div> -->
          <van-field
            :label="$t('second.Proposal_Name')"
            v-model="name"
            :placeholder="$t('second.Proposal_Name')"
            maxlength="20"
            required
            :rules="[
              {
                required: true,
                message: $t('second.Please_enter_the_proposal_name'),
              },
            ]"
          />
        </div>
        <div class="name name1">
          <!-- <div class="line1"><span>*</span>提案简介</div> -->
          <van-field
            :label="$t('second.Proposal_Introduction')"
            v-model="brief"
            maxlength="100"
            type="textarea"
            :placeholder="$t('second.Please_enter_the_proposal_introduction')"
            required
            :rules="[
              {
                required: true,
                message: $t('second.Please_enter_the_proposal_introduction'),
              },
            ]"
          />
        </div>
        <div class="name name1" style="position:relative;">
          <!-- <div class="line1"><span>*</span>提案详情</div> -->
          <!-- <van-field
            label="提案详情"
            v-model="detail"
            maxlength="800"
            type="textarea"
            placeholder="请输入提案详情"
            required
            :rules="[{ required: true, message: '请填写提案详情' }]"
          /> -->
          <div class="detail-sty">
            <div>*</div>
            {{ $t('second.Proposal_Details') }}
          </div>
          <quill-editor
            :content="detail"
            :options="editorOption"
            @change="updateData($event)"
            class="is-editor"
          />
          <span class="is-limit">{{ limitStr.length }}/800</span>
        </div>
        <div class="name" style="padding-bottom: 10px">
          <div class="line1">{{ $t('second.Proposal_File') }}</div>
          <div class="pdf-box">
            <img
              src="@/assets/pdf-icon-2x.png"
              alt=""
              v-if="fileList.length > 0"
            />
            <van-uploader
              max-count="1"
              v-model="fileList"
              multiple
              accept=".pdf"
              upload-icon="plus"
              max-size="10485760"
              @oversize="oversize"
              :after-read="afterRead"
              :before-read="beforeRead"
              @delete="deletePic"
              :class="$store.state.lang == 'zh' ? 'zh' : ''"
            />
          </div>
        </div>
        <div class="over-time">
          *{{ $t('second.The_proposal_will_be_closed_in_X_days') }}
        </div>
        <div class="btn" @click="submitHandler">
          {{ $t('second.Initiate_Proposal') }}
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
const toolbarOptions = []
import { Toast } from 'vant'
import { getSignObj } from '@/utils/index.js'
import { CommitteeAddProposal } from '@/api/detail'
import { upload } from '@/api/enroll'

export default {
  data() {
    return {
      name: '',
      brief: '',
      detail: '',
      picUrl: '',
      picName: '',
      fileList: [],
      editorOption: {
        //  富文本编辑器配置
        modules: {
          //工具栏定义的
          toolbar: toolbarOptions,
        },
        //主题
        theme: 'snow',
        placeholder: '请输入提案详情',
      },
      limitStr: '',
    }
  },
  methods: {
    oversize() {
      Toast.fail(this.$t('enroll.Image_size_limit_exceeded'))
    },
    updateData(e) {
      this.detail = e.html
      let str = e.html.replace(/<\/?.+?\/?>/g, '')
      str = str.replace(/[ ]|[&nbsp;]/g, '')
      this.limitStr = str
      if (str.length > 800) {
        Toast.fail(
          this.$t('enroll.The_length_should_not_exceed_800_characters')
        )
      }
    },
    beforeRead(file) {
      if (file.type != 'application/pdf') {
        Toast(this.$t('second.Please_upload_the_file_in_format'))
        return false
      }
      return true
    },
    async submitHandler() {
      this.$refs.pageForm
        .validate()
        .then((data) => {
          console.log(data)
          if (!this.detail) {
            Toast(this.$t('second.Please_enter_the_proposal_details'))
            return
          }
          this.CommitteeAddProposal()
        })
        .catch((e) => {
          console.log('check2', e)
        })
    },
    async CommitteeAddProposal() {
      // console.log(this.$refs.pageForm)
      // let data={}
      let _this = this
      try {
        let data = await getSignObj()

        data.title = this.name
        data.background = this.brief
        data.detail = this.detail
        data.pdf_url = this.picUrl
        data.annex_name = this.picName

        let isRes = await CommitteeAddProposal(data)
        if (isRes.code == 0) {
          Toast.success(this.$t('second.Successfully_Initiated'))
          setTimeout(() => {
            _this.$router.push('/')
          }, 2000)
        }
      } catch (e) {
        Toast.fail(this.$t('second.Failed_to_initiate'))
      }
    },
    onFailed() {},
    async afterRead(file) {
      // 上传图片到服务器
      console.log(file)
      let fd = new FormData()
      fd.append('file', file.file)
      let loading = Toast.loading()

      try {
        let res = await upload(fd)
        if (res.code == 0) {
          Toast.success(this.$t('enroll.Upload_Successful'))
          this.picUrl = res.url
          this.picName = file.file.name
          // if (res.title && res.title.split('/').length > 1) {
          //   this.picName = res.title.split('/')[1]
          // } else {
          //   this.picName = res.title
          // }
        } else {
          Toast.fail(this.$t('enroll.file_failed'))
          this.fileList = []
        }
      } catch (e) {
        loading.clear()
      }
    },
    // 删除图片
    deletePic() {
      this.picUrl = ''
      this.picName = ''
      this.fileList = []
      // this.checkedPic = false;
    },
  },
  created() {
    this.editorOption.placeholder = this.$t(
      'second.Please_enter_the_proposal_details'
    )
  },
  computed: {},
}
</script>

<style lang="less" scoped>
/deep/ .van-cell__title {
  max-width: 100%;
  white-space: nowrap;
}
.is-limit {
  position: absolute;
  top: 39px;
  right: 10px;
  font-size: 14px;
  color: #9a9fa4;
}
.detail-sty {
  font-size: 12px;
  color: rgb(119, 119, 137);
  font-weight: bold;
  display: flex;
  div {
    color: rgb(238, 10, 36);
    font-size: 14px;
    line-height: 20px;
  }
}
/deep/ .is-editor {
  height: 300px;
  margin-bottom: 20px;
  .ql-toolbar {
    height: 0.01px;
    // padding: 0px;
  }
}
.pdf-box {
  display: flex;
  margin-top: 6px;
  align-items: center;
  img {
    width: 60px;
    height: 66px;
  }
}
/deep/.van-uploader__upload {
  .van-icon {
    &::before {
      // margin: 0 auto;
      margin-left: 20px;
    }
    &::after {
      margin-top: 5px;
      content: 'Upload PDF';
      font-size: 12px;
      display: block;
    }
  }
}
.zh /deep/.van-uploader__upload {
  .van-icon::after {
    content: '上传PDF';
  }
  .van-icon::before {
    // margin: 0 auto;
    margin-left: 12px;
  }
}
/deep/ .van-uploader__preview {
  width: 240px !important;
  .van-uploader__file {
    background-color: #fff;
    width: 180px;
    i {
      display: none;
    }
    &::before {
      img {
      }
    }
  }
}

/deep/ .van-cell {
  padding: 0px;
}
.name1 {
  /deep/ .van-field__control {
    min-height: 90px;
  }
}
.inner {
  background: #eef2f6;
  min-height: 100vh;
  box-sizing: border-box;
  padding-top: 17px;
  padding-bottom: 35px;
  .card {
    padding: 10px 10px 22px 10px;
    box-sizing: border-box;
    width: 93.333%;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 8px;
    .name {
      box-sizing: border-box;
      padding-left: 4px;
      padding-bottom: 20px;
      border-bottom: 1px solid #eeeeee;
      padding-top: 17px;
      .line1 {
        font-size: 12px;
        color: #777789;
        line-height: 16px;
        margin-bottom: 7px;
        font-weight: bold;
        span {
          color: red;
        }
      }
    }
    .over-time {
      padding-top: 14px;
      color: #e02a2a;
      font-size: 12px;
    }
    .btn {
      width: 310px;
      height: 45px;
      margin: 0 auto;
      background: #0073ee;
      border-radius: 6px;
      text-align: center;
      line-height: 45px;
      color: #fff;
      margin-top: 44px;
    }
  }
}
/deep/div.van-cell {
  flex-direction: column;
  .van-cell__title {
    font-size: 12px;
    color: #777789;
    line-height: 16px;
    margin-bottom: 7px;
    font-weight: bold;
  }
  &.van-cell--required::before {
    left: 0;
  }
  &.van-cell--required .van-cell__title {
    padding-left: 7px;
  }
}
</style>
