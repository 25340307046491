var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inner"},[_c('div',{staticClass:"card"},[_c('van-form',{ref:"pageForm",attrs:{"validate-first":""},on:{"failed":_vm.onFailed}},[_c('div',{staticClass:"name"},[_c('van-field',{attrs:{"label":_vm.$t('second.Proposal_Name'),"placeholder":_vm.$t('second.Proposal_Name'),"maxlength":"20","required":"","rules":[
            {
              required: true,
              message: _vm.$t('second.Please_enter_the_proposal_name'),
            } ]},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('div',{staticClass:"name name1"},[_c('van-field',{attrs:{"label":_vm.$t('second.Proposal_Introduction'),"maxlength":"100","type":"textarea","placeholder":_vm.$t('second.Please_enter_the_proposal_introduction'),"required":"","rules":[
            {
              required: true,
              message: _vm.$t('second.Please_enter_the_proposal_introduction'),
            } ]},model:{value:(_vm.brief),callback:function ($$v) {_vm.brief=$$v},expression:"brief"}})],1),_c('div',{staticClass:"name name1",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"detail-sty"},[_c('div',[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t('second.Proposal_Details'))+" ")]),_c('quill-editor',{staticClass:"is-editor",attrs:{"content":_vm.detail,"options":_vm.editorOption},on:{"change":function($event){return _vm.updateData($event)}}}),_c('span',{staticClass:"is-limit"},[_vm._v(_vm._s(_vm.limitStr.length)+"/800")])],1),_c('div',{staticClass:"name",staticStyle:{"padding-bottom":"10px"}},[_c('div',{staticClass:"line1"},[_vm._v(_vm._s(_vm.$t('second.Proposal_File')))]),_c('div',{staticClass:"pdf-box"},[(_vm.fileList.length > 0)?_c('img',{attrs:{"src":require("@/assets/pdf-icon-2x.png"),"alt":""}}):_vm._e(),_c('van-uploader',{class:_vm.$store.state.lang == 'zh' ? 'zh' : '',attrs:{"max-count":"1","multiple":"","accept":".pdf","upload-icon":"plus","max-size":"10485760","after-read":_vm.afterRead,"before-read":_vm.beforeRead},on:{"oversize":_vm.oversize,"delete":_vm.deletePic},model:{value:(_vm.fileList),callback:function ($$v) {_vm.fileList=$$v},expression:"fileList"}})],1)]),_c('div',{staticClass:"over-time"},[_vm._v(" *"+_vm._s(_vm.$t('second.The_proposal_will_be_closed_in_X_days'))+" ")]),_c('div',{staticClass:"btn",on:{"click":_vm.submitHandler}},[_vm._v(" "+_vm._s(_vm.$t('second.Initiate_Proposal'))+" ")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }